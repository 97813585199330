import React from "react"
import styled from "styled-components"

import Layout from "../layout"
import { Message } from "../components/l10n"
import illustration from "../images/svg/thanks.svg"
import { Content, Title } from "../components/styled"

const Image = styled.img`
  margin: 30px;
`

const NotFoundPage = () => (
  <Layout noindex>
    <Content>
      <Title>
        <Message id="not_found" />
      </Title>
      <Message id="not_found_text" Tag="p" />
      <Image src={illustration} />
    </Content>
  </Layout>
)

export default NotFoundPage
